import React from 'react';
import { Box, ImageList, ImageListItem } from '@mui/material';

const Photos: React.FC = () => {
    return (
        <Box sx={{ width: 730, maxWidth: '90vw' }}>
            <ImageList variant="masonry" cols={2} gap={8}>
                {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            srcSet={`${item.img}?w=365&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=365&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
};

export default Photos;

const itemData = [
    {
        img: '/assets/band-1.jpg',
        title: 'band-1',
    },
    {
        img: '/assets/gig-1.jpg',
        title: 'gig-1',
    },
    {
        img: '/assets/band-2.jpg',
        title: 'band-2',
    },
    {
        img: '/assets/gig-2.jpg',
        title: 'gig-2',
    },
    {
        img: '/assets/band-3.jpg',
        title: 'band-3',
    },
    {
        img: '/assets/gig-3.jpg',
        title: 'gig-3',
    },
    {
        img: '/assets/band-4.jpg',
        title: 'band-4',
    },
    {
        img: '/assets/gig-4.jpg',
        title: 'gig-4',
    },
    {
        img: '/assets/band-5.jpg',
        title: 'band-5',
    },
    {
        img: '/assets/gig-5.jpg',
        title: 'gig-5',
    },
    {
        img: '/assets/band-6.jpg',
        title: 'band-6',
    },
    {
        img: '/assets/gig-6.jpg',
        title: 'gig-6',
    },
];