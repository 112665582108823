import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

const JustText = styled(Typography)(({ theme }) => ({
    fontFamily: '"Madimi One", sans-serif',
    fontWeight: 500,
    fontSize: '1.2rem',
    margin: theme.spacing(1),
    color: "white"
}));

const Contact: React.FC = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: 1000,
            maxWidth: '90vw',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            gap: theme => theme.spacing(2)
        }}>
            <a href="mailto:thehofhorse@gmail.com" target="_blank" rel="noopener noreferrer">
                <MailOutlineIcon sx={{ color: 'black' }} fontSize='large' />
            </a>
            <a href="https://www.facebook.com/hofhorse" target="_blank" rel="noopener noreferrer">
                <FacebookOutlinedIcon sx={{ color: 'black' }} fontSize='large' />
            </a>
            <a href="https://www.instagram.com/thehofhorse" target="_blank" rel="noopener noreferrer">
                <InstagramIcon sx={{ color: 'black' }} fontSize='large' />
            </a>
            <a href="https://www.youtube.com/channel/UCWFJ6FMXRrvKrw0QOYMs4vQ" target="_blank" rel="noopener noreferrer">
                <YouTubeIcon sx={{ color: 'black' }} fontSize='large' />
            </a>
            <a href="https://thehofhorse.bandcamp.com/" target="_blank" rel="noopener noreferrer">
                <img
                    srcSet={`/assets/bandcamp-logo.png`}
                    src={`/assets/bandcamp-logo.png`}
                    alt="bandcamp logo"
                    loading="lazy"
                    height="35px"
                />
            </a>
            <a href="https://soundcloud.com/the-h-of-horse" target="_blank" rel="noopener noreferrer">
                <img
                    srcSet={`/assets/soundcloud-logo.png`}
                    src={`/assets/soundcloud-logo.png`}
                    alt="soundcloud logo"
                    loading="lazy"
                    height="35px"
                />
            </a>
            <a href="https://open.spotify.com/artist/4J5DnD2lAInEiDCvcn87gY" target="_blank" rel="noopener noreferrer">
                <img
                    srcSet={`/assets/spotify-logo.png`}
                    src={`/assets/spotify-logo.png`}
                    alt="spotify logo"
                    loading="lazy"
                    height="30px"
                />
            </a>
            <a href="https://music.amazon.com/artists/B083Q3469N/the-h-of-horse" target="_blank" rel="noopener noreferrer">
                <img
                    srcSet={`/assets/amazon-music-logo.png`}
                    src={`/assets/amazon-music-logo.png`}
                    alt="amazon music logo"
                    loading="lazy"
                    height="30px"
                />
            </a>
            <a href="https://music.apple.com/be/artist/the-h-of-horse/1494383245" target="_blank" rel="noopener noreferrer">
                <img
                    srcSet={`/assets/apple-music-logo.png`}
                    src={`/assets/apple-music-logo.png`}
                    alt="apple music logo"
                    loading="lazy"
                    height="30px"
                />
            </a>
            <a href="https://music.youtube.com/channel/UCEOtx_0Szt8SQde-D-s8o4Q" target="_blank" rel="noopener noreferrer">
                <img
                    srcSet={`/assets/youtube-music-logo.png`}
                    src={`/assets/youtube-music-logo.png`}
                    alt="youtube music logo"
                    loading="lazy"
                    height="30px"
                />
            </a>

        </Box>
    )
};

export default Contact;
