import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const BandName = styled(Typography)(({ theme }) => ({
    fontFamily: '"Madimi One", sans-serif',
    fontWeight: 700,
    fontSize: '4rem',
    marginBottom: theme.spacing(1),
    color: "white",
}));

const Baseline = styled(Typography)(({ theme }) => ({
    fontFamily: '"Madimi One", sans-serif',
    fontWeight: 500,
    fontSize: '1.2rem',
    color: "white"
}));

const Members = styled(Typography)(({ theme }) => ({
    fontFamily: '"Madimi One", sans-serif',
    fontWeight: 300,
    fontSize: '0.8rem',
    color: "black"
}));

const Bio = styled(Typography)(({ theme }) => ({
    fontFamily: '"Madimi One", sans-serif',
    fontWeight: 400,
    fontSize: '1.0rem',
    marginTop: theme.spacing(1),
    color: "white"
}));

const Name: React.FC = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 1000,
            maxWidth: '90vw',
        }}>
            <BandName sx={{ textAlign: 'center' }}>
                The H of Horse
            </BandName>
            <Baseline sx={{ textAlign: 'center' }}>
                A rock band from Mechelen, known for their energetic performances and distinctive sound that blends elements of classic and contemporary rock, characterized by powerful guitar riffs, dynamic drumming, and compelling vocals.
            </Baseline>
            <Members sx={{ textAlign: 'center' }}>
                @Drums: Ben Gladiné -
                @Guitar: Kilian Gladiné -
                @Bass&Backings: Bram Aerts -
                @Voice: Dries Van Itterbeeck
            </Members>
            <Bio sx={{ textAlign: 'center' }}>
                A gathering of four high-school friends who are still, after a decade, creating songs to blow your speakers.
            </Bio>
        </Box>
    )
};

export default Name;
