import { Box } from '@mui/material';
import './App.css';
import Photos from './Photos.tsx';
import Contact from './Contact.tsx';
import Name from './Name.tsx';

function App() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      background: 'url("/assets/album-2-2.jpg") center center fixed',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed'
    }}>
      <Name />
      <Contact />
      <Photos />
    </Box >
  );
}

export default App;
